import { ACTION_SET_WARNING } from '../actionTypes/adultWarning';

import { ADULT_WARNING_COOKIE, COOKIE_WARNING_EXPIRES } from '../constants';


export const actionSetWarning = warning => ({
  type: ACTION_SET_WARNING,
  payload: { warning },
});

export const actionAcceptWarning = () => (
  (dispatch, getState, { setCookie }) => {
    const warning = 1;
    setCookie(
      ADULT_WARNING_COOKIE,
      warning,
      {
        expires: COOKIE_WARNING_EXPIRES,
        path: '/',
      }
    );

    dispatch(actionSetWarning(warning));
  }
);
