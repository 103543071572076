import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import useCurrentLangForUrl from '../../../../core/i18n/hooks/useCurrentLangForUrl';

import { l } from 'react-redux-app/lib/modules/i18n/utils';
import { get182257Url, getTermsUrl, getPrivacyUrl } from '../../../utils/url';

import { MODAL_DIALOG__ADULT_WARNING } from '../../../constants/dialogs';

import { getSiteName } from '../../../../initSettings/selectors';

import { actionAcceptWarning } from '../../../actions/adultWarning';
import { actionHideAdultWarningDialog } from '../../../actions/dialogs/adultWarningDialog';

import { Button } from 'react-redux-app/lib/modules/button/components';
import { ButtonSet } from 'react-redux-app/lib/modules/buttonSet/components';
import { Modal } from 'react-redux-app/lib/modules/modal/containers';
import HtmlFromLangKey from '../../../../core/components/HtmlFromLangKey';
import Logo from '../../Logo';

import styles from './styles/index.css.json';


export default () => {
  const dispatch = useDispatch();

  const siteName = useShortSelector(getSiteName);
  const langForUrl = useCurrentLangForUrl();

  return (
    <Modal
      modalName={MODAL_DIALOG__ADULT_WARNING}
      isShowCloseInRightTop={false}
      isCloseOnEscape={false}
      theme={{ body: styles.body }}
      className="text-center"
    >
      <Logo />

      <HtmlFromLangKey
        langKey="html-text.msg.adult-warning.top"
        langKeyParams={{
          siteName,
          usc182257Url: get182257Url(langForUrl),
          termsUrl: getTermsUrl(langForUrl),
          privacyUrl: getPrivacyUrl(langForUrl),
        }}
        className={classnames('text-left', styles.text)}
      />

      <ButtonSet>
        <Button
          type="button"
          onClick={() => {
            dispatch(actionAcceptWarning());
            dispatch(actionHideAdultWarningDialog());
          }}
        >
          {l('action.i-am-over-18')}
        </Button>
      </ButtonSet>

      <HtmlFromLangKey
        langKey="html-text.msg.adult-warning.bottom"
        langKeyParams={{ privacyUrl: getPrivacyUrl(langForUrl) }}
        className={classnames('text-left', styles.text)}
      />
    </Modal>
  );
};
